<template>
  <form v-if="questionnaireSaved !== true" @submit.prevent="getForm">
    <h1>{{ questionnaire.titreQuestionnaire }}</h1>

    <!-- boucle sur les sections -->
    <fieldset
      v-for="section in questionnaire.sections"
      :key="section.titreSection"
    >
      <!-- La légende est rendue invisible avec le CSS. -->
      <!-- N'est donc utile que pour la sémantique du formulaire. -->
      <legend>{{ section.titreSection }}</legend>

      <!-- boucle sur les questions -->
      <div :class="`conteneur-blanc`">
        <p class="titre-section">{{ section.titreSection }}</p>
        <hr />
        <!-- impression d'un ou plusieurs paragraphe d'intro -->
        <p
          v-for="paragraphe in section.texteIntroQuestionnaire"
          :key="paragraphe"
        >
          {{ paragraphe }}
        </p>

        <!-- conteneur avec toutes les questions -->
        <!-- application conditionnelle sur le conteneur d'une grille/class CSS -->
        <div
          :class="`${section.classeCssSection ? section.classeCssSection : ''}`"
        >
          <div
            :class="`sous-conteneur-blanc ${
              question.classeCssQuestion ? question.classeCssQuestion : ''
            }`"
            v-for="question in section.contenuSection"
            :key="question.question"
          >
            <!-- question en gras -->
            <strong
              >{{ question.question }} <a class="pointRouge"> *</a></strong
            >
            <p>{{ question.aide }}</p>
            <!-- <strong v-if="section.titreSection === 'Section 1'">
              {{ question.question }}
            </strong>
            <strong v-else>
              {{ question.question }} <a class="pointRouge"> *</a>
            </strong> -->

            <!-- input text/email -->
            <div
              class="section-choix-reponses"
              v-if="
                question.typeSaisieReponse === 'text' ||
                question.typeSaisieReponse === 'text-required' ||
                question.typeSaisieReponse === 'email'
              "
            >
              <InputTextMaison
                ref="champs"
                :placeholderProp="question.question"
                :typeProp="question.typeSaisieReponse"
                v-model="question.reponseChoisie"
              />
            </div>
            <div
              v-if="question.typeSaisieReponse === 'ranking'"
              class="section-choix-reponses"
            >
              <div
                v-for="sousQuestion in question.sousQuestions"
                :key="sousQuestion.id"
                class="sous-questions-ranking"
              >
                <p>{{ sousQuestion.question }}</p>
                <Dropdown
                  optionLabel="texteReponse"
                  :optionValue="sousQuestion.reponsesPossibles.texteReponse"
                  :options="sousQuestion.reponsesPossibles"
                  placeholder="Sélectionner..."
                  v-model="sousQuestion.reponseChoisie"
                  class="dropdown"
                />
              </div>
            </div>

            <!-- input number -->
            <div
              v-if="question.typeSaisieReponse === 'number'"
              class="section-choix-reponses"
            >
              <InputNumberMaison
                @change="submit"
                v-model="question.reponseChoisie"
              />
            </div>

            <!-- input checkboxes -->
            <div
              required
              v-if="question.typeSaisieReponse === 'checkbox'"
              class="section-choix-reponses"
            >
              <InputCheckboxesMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
              />
            </div>

            <!-- input radio -->
            <div
              v-if="question.typeSaisieReponse === 'radio'"
              class="section-choix-reponses"
            >
              <InputRadioMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
              />
            </div>

            <!-- input radio grid -->
            <div
              v-if="question.typeSaisieReponse === 'radio-grid'"
              class="section-choix-reponses"
            >
              <InputGrilleRadioMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
              />
            </div>

            <!-- dropdown -->
            <div
              v-if="question.typeSaisieReponse === 'dropdown'"
              class="section-choix-reponses"
            >
              <InputDropdownMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
                :questionId="question.id"
                :order="question.order"
                @change="dropdownChange"
              />
            </div>

            <!-- input multi text -->
            <div
              v-if="question.typeSaisieReponse === 'multi-text'"
              class="section-choix-reponses"
            >
              <InputMultiTextMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
              />
            </div>

            <!-- input multi integer -->
            <div
              v-if="question.typeSaisieReponse === 'multi-integer'"
              class="section-choix-reponses"
            >
              <InputMultiIntegerMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
              />
            </div>

            <!-- input text area pour chaque champs qui permet d'importer -->
            <div v-if="question.dataImport">
              <InputTextareaMaison
                v-model="question.reponsesImporter"
                :questionProp="question"
              />
            </div>

            <div v-if="question.typeSaisieReponse === 'tag'">
              <InputTagMaison
                ref="champs"
                v-model="question.reponseChoisie"
                :questionProp="question"
                :questionId="question.id"
                :filterWith="question.filterWith"
                @change="dropdownChange"
                :regions="regions"
                :mrc="mrc"
                :municipalites="municipalites"
              />
            </div>
          </div>
          <!-- fin sous-conteneur-blanc -->
        </div>
        <!-- fin conteneur avec toutes les questions -->
      </div>
      <!-- fin conteneur blanc -->
    </fieldset>
    <!-- fin boucle sur les sections -->

    <!-- Conteneur remonté de 20 pixels avec le CSS. -->
    <!-- Comme ça, il a l'air visuellement de faire partie de la section précédente. -->
    <div class="conteneur-bouton-bleu conteneur-blanc">
      <div class="conteneur-bouton-bleu">
        <button class="boutonbleu" type="submit">Envoyer le formulaire</button>
      </div>
    </div>
  </form>
  <QuestionnaireSaved v-else></QuestionnaireSaved>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import InputTextMaison from '@/components/input/InputTextMaison.vue';
import InputMultiIntegerMaison from '@/components/input/InputMultiIntegerMaison.vue';
import InputNumberMaison from '@/components/input/InputNumberMaison.vue';
import InputDropdownMaison from '@/components/input/InputDropdownMaison.vue';
import InputCheckboxesMaison from '@/components/input/InputCheckboxesMaison.vue';
import InputRadioMaison from '@/components/input/InputRadioMaison.vue';
import InputGrilleRadioMaison from '@/components/input/InputGrilleRadioMaison.vue';
import { QuestionnaireDTO } from '@/DTO/QuestionnaireDTO';
import { QuestionnaireDropdownChangeResponse } from '@/DTO/QuestionnaireDropdownChangeResponse';
import InputMultiTextMaison from '../components/input/InputMultiTextMaison.vue';
import InputTextareaMaison from '@/components/input/InputTextareaMaison.vue';
import InputTagMaison from '../components/input/InputTagMaison.vue';
import { FilterKeyValue } from '@/DTO/FilterKeyValue';
import QuestionnaireSaved from '@/components/QuestionnaireSaved.vue';
import Dropdown from 'primevue/dropdown';
import endroit from '@/store/endroit';
// PrimeVue

export default defineComponent({
  name: 'Questionnaire',
  components: {
    InputNumberMaison,
    InputDropdownMaison,
    InputTextMaison,
    InputCheckboxesMaison,
    InputGrilleRadioMaison,
    InputRadioMaison,
    InputMultiTextMaison,
    InputTextareaMaison,
    InputTagMaison,
    QuestionnaireSaved,
    InputMultiIntegerMaison,
    Dropdown
  },
  data () {
    return {
      // fichier JSON contenant tout le matériel pour générer un formulaire
      questionnaire: {} as QuestionnaireDTO,
      errors: false,
      questionnaireSaved: false,
      municipalitesAlreadySaved: [],
      regions: [],
      mrc: [],
      municipalites: []
    };
  },
  methods: {
    dropdownChange (response: QuestionnaireDropdownChangeResponse) {
      for (const [key, section] of Object.entries(
        this.questionnaire.sections
      )) {
        if (section.contenuSection) {
          for (var i = 0; i < section.contenuSection.length; i++) {
            var question = section.contenuSection[i];
            if (question.dependsOn) {
              if (question.dependsOn.parentId === response.questionId) {
                if (!question.filterWith) {
                  question.filterWith = {} as FilterKeyValue;
                }
                question.filterWith.parentId = response.responseId;
                if (question.selfInputComponent.updateFilter) {
                  question.selfInputComponent.updateFilter();
                }
              }
            }
          }
        }
      }
    },
    getForm () {
      (this.$refs.champs as any).forEach((element: any) => {
        if (element.validate) {
          element.validate();
          if (element.errorMessage.length > 0) {
            this.errors = true;
          }
        }
      });
      // variables
      const myForm: any = {};
      let objQuestionActuelle: any = {};

      // boucle cherchant une à une les questions et réponses fournies par l'utilisateur
      for (const [key, section] of Object.entries(
        this.questionnaire.sections
      )) {
        if (section.contenuSection) {
          if (!(key in myForm)) {
            myForm[key] = {};
          }
          for (const [questionId, question] of Object.entries(
            section.contenuSection
          )) {
            objQuestionActuelle = {
              question_id: questionId,
              question: question.question,
              reponse: question.reponseChoisie,
              reponsesImporter: question.reponsesImporter
            };
            myForm[key][questionId.toString()] = objQuestionActuelle;
            if (question.sousQuestions !== undefined) {
              for (const [sousQuestionId, sousQuestion] of Object.entries(
                question.sousQuestions
              )) {
                objQuestionActuelle = {
                  question_id: sousQuestionId,
                  question: sousQuestion.question,
                  reponse: sousQuestion.reponseChoisie,
                  reponsesImporter: sousQuestion.reponsesImporter,
                  question_id_parent: questionId
                };
                myForm[key][sousQuestionId.toString()] = objQuestionActuelle;
              }
            }
          }
        }
      }

      // TODO: dont submit if errors
      if (!this.errors) {
        this.submit(myForm);
      }

      this.errors = false;
    },
    submit (myForm: any) {
      this.$loading.startLoading();
      let endroitId = myForm as any;
      var endroitReponses = [];
      if (this.questionnaire.id === 1) {
        endroitReponses = this.municipalitesAlreadySaved[
          myForm.informationGenerale[6].reponse.id
        ] as any;
        endroitId = myForm.informationGenerale[6].reponse.id;
      } else if (this.questionnaire.id === 2) {
        endroitReponses = this.municipalitesAlreadySaved[
          myForm.informationGenerale[5].reponse.id
        ] as any;
        endroitId = myForm.informationGenerale[5].reponse.id;
      } else if (this.questionnaire.id === 3) {
        endroitReponses = this.municipalitesAlreadySaved[
          myForm.informationGenerale[5].reponse.id
        ] as any;
        endroitId = myForm.informationGenerale[5].reponse.id;
      }

      if (
        endroitReponses !== undefined &&
        endroitReponses[this.questionnaire.id] === true
      ) {
        this.$toast.error('La municipalité a déjà répondu au questionnaire');
        this.$loading.stopLoading();
      } else {
        // this.questionnaire.idQuestionEndroit.forEach((id) => {
        //   endroitId = endroitId[id];
        // });

        // endroitId = endroitId.reponse.id;
        this.$store
          .dispatch('questionnaire/saveQuestionnaire', {
            questionnaire: myForm,
            endroitId,
            questionnaireId: this.$route.params.id
          })
          .then((response) => {
            this.$loading.stopLoading();
            // this.$toast.success('Le questionnaire a été envoyé avec succès.');
            this.questionnaireSaved = true;
          });
      }
    },
    formatQuestionnaire () {
      const sectionsKeys = Object.keys(this.questionnaire.sections);
      sectionsKeys.forEach((sectionKey) => {
        const section = this.questionnaire.sections[sectionKey];
        if (section.contenuSection) {
          for (const question of Object.values(section.contenuSection)) {
            question.setSelfInputConponent = (component) => {
              question.selfInputComponent = component;
            };
          }
        }
      });
    }
  },
  mounted () {
    this.$loading.startLoading();
    this.$store
      .dispatch('questionnaire/getQuestionnaire', {
        questionnaireName: this.$route.params.id
      })
      .then((response) => {
        this.questionnaire = response[0];
        this.municipalitesAlreadySaved = response[1];
        this.formatQuestionnaire();
        this.regions =
          response[0].sections.informationGenerale.contenuSection[3].reponsesPossibles;
        this.mrc =
          response[0].sections.informationGenerale.contenuSection[4].reponsesPossibles;
        this.municipalites =
          response[0].sections.informationGenerale.contenuSection[5].reponsesPossibles;
        this.$loading.stopLoading();
      });
  }
});
</script>

<style lang="scss" scoped>
.pointRouge {
  color: red;
}

// groupe de règles 1 - titre principal et éléments plus sémantiques que visuels
h1 {
  margin: 1vh 2.5vw;
  padding: 2.5% 0 2.5%;
  text-align: left;
}

fieldset {
  border: none;
  // pcq fieldset a un padding par défaut
  padding: 0;

  legend {
    display: none;
  }
}

// groupe de règles 2 - conteneur blanc
.conteneur-blanc {
  background: white;
  border-radius: 10px;
  margin: 1vh 2.5vw;
  padding: 2.5%;
  text-align: left;
  width: 95%;

  // pour styliser le titre des sections
  // semblable à la classe titreFiltre, employée ailleurs dans l'appli
  .titre-section {
    font-family: 'Encode Sans';
    font-weight: 700;
    text-transform: uppercase;
  }

  // Cette classe sert à servir de rappel quand le contenu n'a pas de caractère définitif.
  .rouge-icigo {
    background: lightcoral !important;
  }

  .sous-conteneur-blanc {
    // border-bottom: 2px solid #f4f4f4;
    padding: 2.5% 0 2.5%;

    // pour que tout sauf le dernier élément ait un diviseur gris
    &:not(:last-child) {
      border-bottom: 2px solid #f4f4f4;
    }

    .section-choix-reponses {
      padding-top: 25px;
    }
  }
}

// groupe de règles 3 - bouton bleu
.conteneur-bouton-bleu {
  display: flex;
  justify-content: right;
  // pour coller le conteneur blanc du bouton bleu sur la zone blanche qui le précède et donner l'impression qu'il ne s'agit que d'une seule et même section
  margin-top: -20px;

  .boutonbleu {
    background-color: #f0753b;
    border-radius: 5px;
    box-shadow: none;
    border: none;
    color: white;
    cursor: pointer;
    font-weight: 700;
    height: 36px;
    // 25px sert à empêcher le chevauchement du bouton bleu sur la ligne noire en mobile
    margin: 25px 5px 5px;
    width: 230px;
  }
}

// groupe de règles 4 - media queries et section 1 du questionnaire */
@media screen and (max-width: 880px) {
  // section 1 s'affiche en rangée plutôt que dans une grille
  .grid-section-1 {
    display: block;
  }
}
@media screen and (min-width: 881px) {
  .grid-section-1 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    // pousse certains éléments de la grille pour une plus grande harmonie visuelle
    .sous-conteneur-blanc {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
.grid-section-1 .p-inputtext,
.grid-section-1 .p-dropdown {
  width: 80%;
}
.sous-questions-ranking {
  display: flex;
  gap: 5rem;
  padding-top: 2rem;
  align-items: center;
}
</style>

<style lang="scss">
// Règles pour tous les enfants de Questionnaire.vue, d'où l'absence du paramètre "scoped".
.error-box {
  border: 1px solid red;
  border-radius: 0.5rem;
  color: red;
  margin: 15px 0 15px 0;
  padding: 15px;
}
</style>
