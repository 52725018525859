import { DescriptionGraphiqueDTO } from '@/DTO/DescriptionGraphiqueDTO';
import { DescriptionGraphiqueDTOColonneTableau } from '@/DTO/DescriptionGraphiqueDTOColonneTableau';
import { RecensementDataItem } from '@/DTO/RecensementDataItem';
import { TableauLigneDTO } from '@/DTO/Tableau/TableauLigneDTO';
import { EndroitSelectionHelper } from '@/helpers/EndroitSelectionHelper';
import { toHandlers, inject, Ref } from 'vue';

export class TableauLogicHelper {
    private trueData: Array<TableauLigneDTO>;
    private colonnes: Map<string, Array<string>>;
    private colonnesTop: Array<string>;
    public TrueData: Array<TableauLigneDTO>;
    public Colonnes: Array<string>;
    public ColonnesTop: Array<string>;
    public nomTheme: string;
    public initialRecensementsData: Map<string, Array<RecensementDataItem>>;
    public graphInfo: DescriptionGraphiqueDTO;
    public data: any;

    public constructor (graphInfo: DescriptionGraphiqueDTO, data: any) {
      this.trueData = new Array<TableauLigneDTO>();
      this.colonnes = new Map<string, Array<string>>();
      this.colonnesTop = new Array<string>();
      this.nomTheme = 'nomEndroit';
      this.initialRecensementsData = new Map<string, Array<RecensementDataItem>>();
      this.graphInfo = graphInfo;
      this.data = data;
      this.TrueData = new Array<TableauLigneDTO>();
      this.Colonnes = new Array<string>();
      this.ColonnesTop = new Array<string>();
    }

    public init () {
      if (this.graphInfo.graphType === 'tarte') {
        const endroitSelectionHelper = inject('endroitSelectionHelper') as Ref<EndroitSelectionHelper>;
        const endroitsDesactives = endroitSelectionHelper.value.getAllDesactivatedEndroits();
        this.data.data.forEach((question:any) => {
          question.forEach((questionReponse:any) => {
            if (questionReponse.endroitsIds) {
              questionReponse.endroitsIds.forEach((endroitReponse:any) => {
                if (endroitsDesactives.includes(endroitReponse._id)) {
                  // questionReponse.total = questionReponse.total - 1;
                  questionReponse.endroitsIds = questionReponse.endroitsIds.filter((x :any) => x._id !== endroitReponse._id);
                }
              });
            }
          });
        });
      }
      if (this.graphInfo.tableauSeriesBy !== undefined) {
        this.nomTheme = this.graphInfo.tableauSeriesBy;
      }
      this.organizeRecensements();
      this.setColonnes();
      this.setData();
      this.inverseDonneesEtColonnes();
      this.trieDonneesEtColonnesPourcentageEtTotal();
      this.trieDonneesTableauFieldCombinaison();
      this.orderColumnsAndData();
      this.formatTrueData();
    }

    public getNumberLine (text: string) {
      if (typeof text === 'number' || (typeof text === 'string' && text.indexOf('(') === -1)) {
        return 1;
      } else {
        return 2;
      }
    }

    public getFirstLine (text: string | number) {
      if (typeof text === 'number' || text === undefined) {
        return text;
      }
      if (typeof text === 'boolean') {
        return text;
      }
      const indexOfParenthese = text.indexOf('(');
      if (indexOfParenthese === -1) {
        return text;
      } else {
        return text.slice(0, indexOfParenthese - 1);
      }
    }

    public getLastLine (text: string | number) {
      if (typeof text === 'number' || text === undefined) {
        return text;
      }
      const indexOfParenthese = text.indexOf('(');
      if (indexOfParenthese === -1) {
        return text;
      } else {
        const textafterSlice = text.slice(indexOfParenthese);
        return this.graphInfo.tableauSecondaryRemoveParenteses ? textafterSlice.replace('(', '').replace(')', '') : textafterSlice;
      }
    }

    private getIfTotalIsPrimaryValue (graphInfo: DescriptionGraphiqueDTO): boolean {
      return graphInfo.pourcentageGraph === undefined || graphInfo.pourcentageGraph === false;
    }

    public titrePremiereLigneTableau () {
      let municipaliteTitre = 'Municipalité';
      if (this.trueData.length > 1) {
        municipaliteTitre += 's';
      }
      return this.graphInfo.titrePremiereLigneTableau !== undefined ? this.graphInfo.titrePremiereLigneTableau : municipaliteTitre;
    }

    public titreTableau () {
      if (this.graphInfo.injectedName) {
        let name;
        for (const [endroitId, recensementList] of this.data.data) {
          name = recensementList[0].nomEndroit;
        }
        return this.graphInfo.name.replace('{var1}', name).toLocaleUpperCase();
      } else {
        return this.graphInfo.name.toLocaleUpperCase();
      }
    }

    private orderColumnsAndData () {
      this.Colonnes = new Array<string>();
      this.ColonnesTop = new Array<string>();
      this.TrueData = new Array<TableauLigneDTO>();
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        // this.Colonnes = Array.of(...this.colonnes.keys());
        // this.TrueData = this.trueData;
        // this.ColonnesTop = Array.of(...this.colonnesTop.values());
        const colSplitByUnderscore = Array.of(...this.colonnes.keys()).map((col) => col.split('_'));
        const tempChampsParCategories = new Map<string, Array<Array<string>>>();

        for (let x = 0; x < colSplitByUnderscore.length; x++) {
          const col = colSplitByUnderscore[x];
          if (!tempChampsParCategories.has(col[1])) {
            tempChampsParCategories.set(col[1], [col]);
          } else {
            tempChampsParCategories.get(col[1])!.push(col);
          }
        }
        for (const colList of tempChampsParCategories.values()) {
          colList.forEach((col) => {
            this.Colonnes.push(col.join('_'));
          });
        }
      } else {
        this.Colonnes = Array.of(...this.colonnes.keys());
        // remettre dans le bon ordre les colonnes pour le tab 39
        if (this.graphInfo.id === 39) {
          const col0 = this.Colonnes[0];
          const col1 = this.Colonnes[1];
          const col2 = this.Colonnes[2];
          const col3 = this.Colonnes[3];
          const col4 = this.Colonnes[4];
          const col5 = this.Colonnes[5];
          this.Colonnes[0] = col0;
          this.Colonnes[1] = col1;
          this.Colonnes[2] = col3;
          this.Colonnes[3] = col4;
          this.Colonnes[4] = col5;
          this.Colonnes[5] = col2;
        }

        if (this.graphInfo.id === 48) {
          const col0 = this.Colonnes[0];

          const col1 = this.Colonnes[1];

          this.Colonnes[0] = col1;
          this.Colonnes[1] = col0;
        }
      }
      if (this.graphInfo.orderTableau) {
        this.TrueData = [...this.trueData]
        if (this.graphInfo.sortByBackEnd === undefined || this.graphInfo.sortByBackEnd !== true) {
          this.TrueData.sort((a, b) => {
            let colToConsider: string | undefined;
            this.Colonnes.forEach(col => {
              if (a[col] !== b[col]) {
                colToConsider = col;
              }
            });

            // for each colonnes if key is undefined, than the name of the key is Nombre
            // for (let i = 0; i < this.Colonnes.length; i++) {
            //     if (this.Colonnes[i] === undefined) {
            //       this.Colonnes[i] = 'Nombre';
            //     }
            // }

            if (this.graphInfo.tableauColonnesIndexTri !== undefined) {
              colToConsider = this.Colonnes[this.graphInfo.tableauColonnesIndexTri];
            }
            // if (this.graphInfo.id === 126) {
            //   if (a.undefined?.valueNotFormatted === b.undefined.valueNotFormatted) {
            //     return 0;
            //   } else if (a.undefined?.valueNotFormatted < b.undefined.valueNotFormatted) {
            //     return 1;
            //   } else {
            //     return -1;
            //   }
            // }
            if (this.graphInfo.orderTableauPourcentage !== true) {
              if (colToConsider === undefined || a[colToConsider] === b[colToConsider]) {
                return 0;
              } else if (a[colToConsider].valueNotFormatted < b[colToConsider].valueNotFormatted) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (colToConsider === undefined || a[colToConsider] === b[colToConsider]) {
                return 0;
              } else if (a[colToConsider].pourcentage < b[colToConsider].pourcentage) {
                return 1;
              } else {
                return -1;
              }
            }
          });
        } else {
          // filtre
          this.trueData.sort((a, b) => {
            if (a[Object.keys(a)[0]].valueNotFormatted === b[Object.keys(b)[0]].valueNotFormatted) {
              return 0;
            } else if (a[Object.keys(a)[0]].valueNotFormatted < b[Object.keys(b)[0]].valueNotFormatted) {
              return 1;
            } else {
              return -1;
            }
          });
        }
        // this.TrueData = this.trueData;
      } else {
        this.TrueData = this.trueData;
        // console.log(this.TrueData);
        // rajoute le $ au champs moyenne
        if (this.graphInfo.id === 39) {
          // this.TrueData[0]['total-Moyenne provinciale'] = this.TrueData[0]['total-Moyenne provinciale'] + ' $';
          this.TrueData.forEach(data => {
            data['total-Moyenne provinciale'] = data['total-Moyenne provinciale'] + ' $';
          });
        }
      }
      this.ColonnesTop = Array.of(...this.colonnesTop.values());
      if (this.graphInfo.reverseColumnTableau === true) {
        this.Colonnes.reverse();
      }

      if (this.graphInfo.reverseColumnTableauBackendFilter === true) {
        this.TrueData.reverse();
      }
    }

    private formatTrueData () {
      this.TrueData = this.TrueData.map((ligne) => {
        const newLigne = {} as TableauLigneDTO;
        for (const [key, element] of Object.entries(ligne)) {
          if (typeof element === 'object' && 'value' in element) {
            if (this.graphInfo.labelCompresse) {
              newLigne[key] = element.valueNotFormatted.toFixed(0).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ' ') + ' $';
            } else {
              newLigne[key] = element.value;
            }
          } else {
            newLigne[key] = element;
          }
        }
        return newLigne;
      });
      if (this.graphInfo.orderReponsesQuestionnaire === true) {
        this.TrueData.forEach(element => {
          const index = element.valeurPremiereColonne.split('-')[0];
          element.index = index;
        });
        this.TrueData.sort(this.compareReponseIndex);
      }
    }

    private inverseDonneesEtColonnes () {
      if (this.graphInfo.axeTableauInverse) {
        const keyToIgnore = [
          'valeurPremiereColonne',
          'nomPremiereColonne'
        ];
        const trueDataMap = new Map<string, TableauLigneDTO>();
        const trueColumns = new Map<string, Array<string>>();
        this.trueData.forEach((ligne: TableauLigneDTO) => {
          trueColumns.set(ligne.valeurPremiereColonne, [ligne.valeurPremiereColonne])
          for (const [key, value] of Object.entries(ligne)) {
            if (!keyToIgnore.includes(key)) {
              let tableauLigne: TableauLigneDTO;
              if (trueDataMap.has(key)) {
                tableauLigne = trueDataMap.get(key) as TableauLigneDTO;
              } else {
                tableauLigne = {
                  valeurPremiereColonne: key,
                  nomPremiereColonne: key
                };
              }
              tableauLigne[ligne.valeurPremiereColonne] = value;
              trueDataMap.set(key, tableauLigne);
            }
          }
        });
        this.trueData = Array.of(...trueDataMap.values());
        this.colonnes = trueColumns;
      }
    }

    private compareReponseIndex (a: any, b: any) {
      if (parseInt(a.index) < parseInt(b.index)) {
        return -1;
      }
      if (parseInt(a.index) > parseInt(b.index)) {
        return 1;
      }
      return 0;
    }

    private trieDonneesEtColonnesPourcentageEtTotal () {
      if (this.graphInfo.tableauGroupeInfo) {
        const alreadySettedColTotal = new Set<string>();
        const alreadySettedColPourcentage = new Set<string>();
        this.trueData.forEach(element => {
          for (const colName of this.colonnes.keys()) {
            let value = element[colName];
            if (typeof value === 'object' && 'value' in value) {
              value = value.value;
            }
            if (value !== undefined) {
              let alreadySetted = false;
              if (colName.startsWith('pourcentage-')) {
                alreadySettedColPourcentage.add(colName);
                alreadySetted = true;
              }
              if (colName.startsWith('total-')) {
                alreadySettedColTotal.add(colName);
                alreadySetted = true;
              }
              if (this.getNumberLine(value) === 2 && !alreadySetted) {
                element['pourcentage-' + colName] = this.getFirstLine(value);
                element['total-' + colName] = this.getLastLine(value);
              }
            }
          }
        });
        const newColumns = new Map<string, Array<string>>();
        this.colonnes.forEach((value, key) => {
          let trueKey = key;
          if (!alreadySettedColPourcentage.has(key) && !alreadySettedColTotal.has(key)) {
            trueKey = 'pourcentage-' + key;
          }
          newColumns.set(trueKey, [trueKey]);
        });
        this.colonnes.forEach((value, key) => {
          let trueKey = key;
          if (!alreadySettedColPourcentage.has(key) && !alreadySettedColTotal.has(key)) {
            trueKey = 'total-' + key;
          }
          newColumns.set(trueKey, [trueKey]);
        });
        this.colonnes = newColumns;
      }
    }

    private trieDonneesTableauFieldCombinaison () {
      if (this.graphInfo.tableauColoneFieldCombinaison) {
        this.trueData.forEach(element => {
          // TODO:: check colonne pour graph 31
          for (const [colName, colList] of this.colonnes.entries()) {
            const value = element[colName];
            // this.colonnesTop.add(colList[1]);
            if (!this.colonnesTop.includes(colList[1])) {
              this.colonnesTop.push(colList[1]);
            }
            // if (value !== undefined && this.graphInfo.tableauColoneFieldCombinaison) {
            // }
          }

          if (this.graphInfo.id === 31) {
            this.colonnesTop = this.colonnesTop.reverse();
          }
        });
        // const newColumns = new Map<string, Array<string>>();
        // this.graphInfo.tableauColoneFieldCombinaison.forEach((colName: string) => {
        //   this.colonnes.forEach((value, key) => {
        //     newColumns.set(colName + '-' + key, [colName + '-' + key]);
        //   });
        // });
        // this.colonnes = newColumns;
      }
    }

    private organizeRecensements () {
      this.initialRecensementsData = this.data.data;
      if (this.graphInfo.tableauReponseParEndroit) {
        this.nomTheme = 'nomEndroit';
        this.initialRecensementsData = new Map<string, Array<RecensementDataItem>>();
        for (const [endroitId, recensementList] of this.data.data) {
          recensementList.forEach((questionReponse: any) => {
            const endroitsRepondus = questionReponse.endroitsIds;
            if (endroitsRepondus !== undefined) {
              endroitsRepondus.forEach((endroit: any) => {
                this.initialRecensementsData.set(endroit.NOM_GEO, questionReponse.reponse)
              });
            }
          });
        }
      }
      if (this.graphInfo.tableauChampSeparationPrimaire) {
        this.nomTheme = this.graphInfo.tableauChampSeparationPrimaire;
        this.initialRecensementsData = new Map<string, Array<RecensementDataItem>>();
        for (const [endroitId, recensementList] of this.data.data) {
          recensementList.forEach((recensement: RecensementDataItem) => {
            const key = recensement[this.nomTheme];
            if (this.graphInfo.enleverDateCompletionTableau) {
              if (recensement.theme !== 'Date de complétion') {
                if (this.initialRecensementsData.has(key)) {
                  this.initialRecensementsData.get(key)!.push(recensement);
                } else {
                  this.initialRecensementsData.set(key, [recensement]);
                }
              }
            } else {
              if (this.initialRecensementsData.has(key)) {
                this.initialRecensementsData.get(key)!.push(recensement);
              } else {
                this.initialRecensementsData.set(key, [recensement]);
              }
            }
          });
        }
      } else if (this.graphInfo.questionnaireSources || this.graphInfo.questionnaireTableauSources) {
        if (this.graphInfo.enleverDateCompletionTableau) {
          this.initialRecensementsData = new Map<string, Array<RecensementDataItem>>();
          for (const [endroitId, recensementList] of this.data.data) {
            recensementList.forEach((recensement: RecensementDataItem) => {
              if (recensement.theme !== 'Date de complétion') {
                if (this.initialRecensementsData.has(recensement.nomEndroit)) {
                  this.initialRecensementsData.get(recensement.nomEndroit)!.push(recensement);
                } else {
                  this.initialRecensementsData.set(recensement.nomEndroit, [recensement]);
                }
              }
            });
          }
        }
      }
    }

    private getColonneFromRecensement (recensement: RecensementDataItem): { name: string, indexList: Array<string> } {
      let colIndexList = new Array<string>();
      if (this.graphInfo.tableauColoneFieldCombinaison !== undefined) {
        colIndexList = this.graphInfo.tableauColoneFieldCombinaison
      } else if (this.graphInfo.graphType === 'courbe') {
        colIndexList.push('anneeRecensement');
      } else if (this.graphInfo.tableauThemeField) {
        colIndexList.push(this.graphInfo.tableauThemeField);
      } else {
        colIndexList.push('theme');
      }
      const colValues = colIndexList.map(colName => {
        return recensement[colName];
      })
      const columnFieldName = colValues.join('_');
      return { name: columnFieldName, indexList: colValues }
    }

    private setColonnes () {
      this.colonnes = new Map<string, Array<string>>();
      if (this.graphInfo.tableauColonnesUtiliseReponsesPossibles) {
        this.data.reponsesPossibles.forEach((reponsePossible: string) => {
          this.colonnes.set(reponsePossible, [reponsePossible]);
        });
      } else if (this.graphInfo.tableauColonnes) {
        this.graphInfo.tableauColonnes.forEach((colonne: DescriptionGraphiqueDTOColonneTableau) => {
          this.colonnes.set(colonne.displayName, [colonne.field]);
        });
      } else if (this.graphInfo.tableauReponseParEndroit) {
        this.data.data.forEach((questionReponse: any) => {
          questionReponse.forEach((question: any) => {
            this.colonnes.set(question.reponse, [question.reponse])
          });
        });
      } else {
        for (const recensementList of this.initialRecensementsData.values()) {
          recensementList.forEach((recensement: RecensementDataItem) => {
            const col = this.getColonneFromRecensement(recensement);
            this.colonnes.set(col.name, col.indexList)
          });
        }
        if (this.graphInfo.globalTotalField) {
          const colName = this.graphInfo.globalTotalField.field;
          this.colonnes.set(colName, [colName]);
        }
        if (this.graphInfo.moyennes) {
          if (this.graphInfo.moyennes.globale) {
            let primaryKeyPrefix = '';
            const isTotalPrymaryValue = this.getIfTotalIsPrimaryValue(this.graphInfo)
            if (this.graphInfo.tableauGroupeInfo) {
              primaryKeyPrefix = isTotalPrymaryValue ? 'total-' : 'pourcentage-';
              const secondaryKeyPrefix = isTotalPrymaryValue ? 'pourcentage-' : 'total-';
              this.colonnes.set(secondaryKeyPrefix + 'Moyenne provinciale', [secondaryKeyPrefix + 'Moyenne provinciale']);
            }
            this.colonnes.set(primaryKeyPrefix + 'Moyenne provinciale', [primaryKeyPrefix + 'Moyenne provinciale']);
          }
        }
      }
    }

    private setData () {
      const tableauDataParEndroit = new Map<string, TableauLigneDTO>()

      if (this.graphInfo.stackedSeries) {
        for (const [key, value] of this.initialRecensementsData) {
          let ligne: TableauLigneDTO;

          // eslint-disable-next-line prefer-const
          ligne = {
            valeurPremiereColonne: key,
            nomPremiereColonne: key
          };

          tableauDataParEndroit.set(key, ligne);

          for (const valueSub of value) {
            ligne[valueSub.theme] = valueSub.total + ' municipalités';
          }
        }
          this.trueData = Array.of(...tableauDataParEndroit.values());
      } else if (this.graphInfo.tableauReponseParEndroit) {
        for (const [key, value] of this.initialRecensementsData) {
          // console.log(key, value)
          let ligne: TableauLigneDTO;

          // eslint-disable-next-line prefer-const
          ligne = {
            valeurPremiereColonne: key,
            nomPremiereColonne: key
          };

          tableauDataParEndroit.set(key, ligne)
          const test = {
            value: value + ''
          };

          this.data.reponsesPossibles.forEach((reponsePossible: string) => {
            if (!(reponsePossible in ligne) || test.value === reponsePossible) {
              const value = test.value === reponsePossible;
              ligne[reponsePossible] = value;
            }
          });
        }
        this.trueData = Array.of(...tableauDataParEndroit.values());
      } else {
        for (const recensementList of this.initialRecensementsData.values()) {
          recensementList.forEach((recensement: RecensementDataItem, index: number) => {
            const key = this.nomTheme;
            let rowValue = recensement[key];
            const colPourDonnees = this.getColonneFromRecensement(recensement);
            const idWithNombreColonne = [126, 128, 130, 134, 141, 142, 143, 144, 145, 153, 155];
            if (rowValue === undefined && idWithNombreColonne.includes(this.graphInfo.id)) {
              rowValue = 'Nombre';
            }
            let ligne: TableauLigneDTO;
            // Get ligne a modif
            if (!tableauDataParEndroit.has(rowValue)) {
              ligne = {
                valeurPremiereColonne: rowValue,
                nomPremiereColonne: this.nomTheme
              };
              tableauDataParEndroit.set(rowValue, ligne)
            } else {
              ligne = tableauDataParEndroit.get(rowValue) as TableauLigneDTO;
            }
            const cellValue = this.getCellValueFromRecensement(recensement);
            if (this.graphInfo.tableauColonnesUtiliseReponsesPossibles) {
              this.data.reponsesPossibles.forEach((reponsePossible: string) => {
                if (!(reponsePossible in ligne) || cellValue.value === reponsePossible) {
                  const value = cellValue.value === reponsePossible;
                  ligne[reponsePossible] = value;
                }
              });
            } else if (this.graphInfo.tableauColonnes) {
              this.graphInfo.tableauColonnes.forEach((colonne: DescriptionGraphiqueDTOColonneTableau) => {
                if (colonne.field in recensement) {
                  const cellValueItem = this.getCellValueFromRecensementAndField(recensement, colonne.field);
                  ligne[colonne.displayName] = cellValueItem;
                }
              });
            } else {
              ligne[colPourDonnees.name] = cellValue;
            }
            if (this.graphInfo.moyennes) {
              const isTotalPrymaryValue = this.getIfTotalIsPrimaryValue(this.graphInfo)
              if (this.graphInfo.moyennes.globale) {
                let primaryKeyPrefix = '';
                if (this.graphInfo.tableauGroupeInfo) {
                  primaryKeyPrefix = isTotalPrymaryValue ? 'total-' : 'pourcentage-';
                  const secondaryKeyPrefix = isTotalPrymaryValue ? 'pourcentage-' : 'total-';
                  ligne[secondaryKeyPrefix + 'Moyenne provinciale'] = recensement[secondaryKeyPrefix + 'Moyenne provinciale'];
                }
                ligne[primaryKeyPrefix + 'Moyenne provinciale'] = recensement[primaryKeyPrefix + 'Moyenne provinciale'];
              }
            }
          });
        }
        if (this.data.totalGlobal) {
          for (const totalItem of this.data.totalGlobal.values()) {
            const totalFormate = this.getCellValueFromRecensement(totalItem).value;
            tableauDataParEndroit.get(totalItem.nomEndroit)![this.graphInfo.globalTotalField!.field] = totalFormate;
          }
        }
        this.trueData = Array.of(...tableauDataParEndroit.values());

        if (this.graphInfo.tableauReverseLigne === true) {
          // this.TrueData[0]['total-Moyenne provinciale'] = this.TrueData[0]['total-Moyenne provinciale'] + ' $';
          this.trueData.reverse();
        }
      }
    }

    private getCellValueFromRecensement (recensement: RecensementDataItem): { value: any, valueNotFormatted: any, pourcentage: any } {
      const valueNotFormatted = recensement.total;
      const pourcentage = recensement.pourcentage;
      let valeurDansLaColonne = '';
      if (isNaN(valueNotFormatted)) {
        valeurDansLaColonne = valueNotFormatted;
      } else {
        if (this.graphInfo.pourcentageTableau && this.graphInfo.contenuUnitesDansLaBande) {
          // pourcentage et unités à afficher, p. ex.: 60 % (50 000 hab.)
          valeurDansLaColonne = `${recensement.pourcentageLabel} (${recensement.totalFormatte} ${this.graphInfo.contenuUnitesDansLaBande})`;
        } else if (this.graphInfo.pourcentageTableau) {
          // pourcentage à afficher, p. ex.: 60 % (50 000)
          valeurDansLaColonne = `${recensement.pourcentageLabel} (${recensement.totalFormatte})`;
        } else if (this.graphInfo.contenuUnitesDansLaBande) {
          // unités à afficher, p. ex.: 50 000 hab.
          valeurDansLaColonne = `${recensement.totalFormatte} ${this.graphInfo.contenuUnitesDansLaBande}`;
        } else if (this.graphInfo.showDollarSign) {
          // signe de dollar à afficher, p. ex.: 50 000$
          valeurDansLaColonne = `${recensement.totalFormatte} $`;
        } else {
          // ni pourcentage, ni unités, p. ex.: 50 000
          valeurDansLaColonne = `${recensement.totalFormatte}`;
        }
      }
      return { value: valeurDansLaColonne, valueNotFormatted, pourcentage };
    }

    private getCellValueFromRecensementAndField (recensement: RecensementDataItem, field: string): { value: any, valueNotFormatted: any } {
      const valueNotFormatted = recensement[field];
      let valeurDansLaColonne = '';
      if (field === 'pourcentage') {
        valeurDansLaColonne = recensement.pourcentageLabel;
      } else if (field === 'total') {
        valeurDansLaColonne = recensement.totalFormatte
        if (this.graphInfo.contenuUnitesDansLaBande) {
          valeurDansLaColonne += ` ${this.graphInfo.contenuUnitesDansLaBande}`;
        } else if (this.graphInfo.showDollarSign) {
          valeurDansLaColonne = `${recensement.totalFormatte} $`;
        }
      } else if (field === 'depensesTotal') {
        valeurDansLaColonne = valueNotFormatted.toLocaleString() + ' $';
      } else {
        valeurDansLaColonne = recensement[field];
      }
      return { value: valeurDansLaColonne, valueNotFormatted };
    }
}
